/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, createContext, useContext, useEffect } from 'react'
import { useAppServices } from '../../../hook/services'
import { useParams } from 'react-router-dom'
import { useTheme } from '../../../context/theme.context'

const initFAQContextPropsState = {
  categories: [],
  faqs: [],
  activeCat: {},
  activeFaq: {},
  setActiveCat: () => {},
  setActiveFaq: () => {},
  setFaqs: () => {},
  setCategories: () => {},
  resetFAQ: () => {},
}

const FAQContext = createContext(initFAQContextPropsState)

const useFAQ = () => {
  return useContext(FAQContext)
}

const FAQProvider = ({ children }: any) => {
  const { company_id, app_id } = useParams()
  const [data, setData] = useState({})
  const [activeCat, setActiveCat] = useState({})
  const [activeFaq, setActiveFaq] = useState({})
  const [categories, setCategories] = useState([])
  const [faqs, setFaqs] = useState([])
  const [loader, setLoader] = useState(true)
  const AppService = useAppServices()
  const { brandings } = useTheme()

  const getDocsData = async () => {
    setLoader(true)
    const { response } = await AppService.k_faq_category.get({
      query: `company_id=${company_id}&app_id=${app_id}`,
    })
    if (response) {
      const firstCat = response.data[0] || {}
      const firstFAQFromCat =
        response.faqs.filter((faq) => faq.category._id == firstCat._id)[0] || {}

      setData(response)
      setCategories(response.data)
      setFaqs(response.faqs)
      setActiveCat(firstCat)
      setActiveFaq(firstFAQFromCat)
      setLoader(false)
    } else {
      setLoader(false)
    }
  }

  const onLoad = () => {
    getDocsData()
  }
  useEffect(onLoad, [])

  const resetFAQ = () => {
    setActiveCat(categories[0] || {})
    const firstFAQFromCat =
      faqs.filter((faq) => faq.category._id == categories[0]._id)[0] || {}
    setActiveFaq(firstFAQFromCat)
  }

  return (
    <FAQContext.Provider
      value={{
        data,
        categories,
        faqs,
        activeCat,
        setActiveCat,
        activeFaq,
        setActiveFaq,
        setFaqs,
        setCategories,
        resetFAQ,
      }}
    >
      {loader ? (
        <div
          style={{
            backgroundColor: brandings.pageBackground,
            color: brandings.textSecondaryColor,
          }}
          className='w-full h-screen flex justify-center items-center flex-col bg-[#1E1E1E] text-gray-200'
        >
          <div>Loading...</div>
        </div>
      ) : (
        children
      )}
    </FAQContext.Provider>
  )
}

export { FAQProvider, useFAQ }
