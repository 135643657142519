import React, { useEffect, useState } from 'react'
import TextEditor from '../../../components/editor'
import CreatableSelect from 'react-select/creatable'
import { useAppServices } from '../../../hook/services'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Swal from 'sweetalert2'
import { useDoc } from '../doc.context'
import { useTheme } from '../../../context/theme.context'
import Button from '../comp/Button'
import Input from '../comp/Input'

function Editor() {
  const { brandings } = useTheme()
  const AppService = useAppServices()
  const { data, formState, setFormState, setData } = useDoc()
  const [categories, setCategories] = useState(
    data?.category
      ?.filter((cat) => cat.type !== 'super-admin')
      ?.map((cat) => ({
        label: cat.name,
        value: cat._id,
      }))
  )
  const [subCategories, setSubCategories] = useState(
    data?.sub_category
      ?.filter((cat) => cat.type !== 'super-admin')
      ?.map((cat) => ({
        label: cat.name,
        value: cat._id,
        category: cat.category,
      }))
  )
  const { company_id, app_id } = useParams()
  const isUpdate = Boolean(formState)

  const initState = {
    categoryId: formState?.categoryId || '',
    subCategoryId: formState?.subCategoryId || '',
    doc: formState?.doc || '',
    title: formState?.title || '',
    status: formState?.status || '',
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: brandings.textFieldBgColor || '#2c2c2c', // Dark background for the control
      color: brandings.textColor, // Font color
      borderColor: brandings.textFieldBorderColor || '#4a4a4a', // Dark border color
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#6a6a6a', // Border color on hover
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: brandings.cardBgColor || '#2c2c2c', // Dark background for the dropdown
      color: brandings.textColor, // Font color for the menu
    }),
    menuList: (base) => ({
      ...base,
      backgroundColor: brandings.cardBgColor || '#2c2c2c', // Match menu background
      color: brandings.textColor,
    }),
    singleValue: (base) => ({
      ...base,
      color: brandings.textColor, // Font color for selected value
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected
        ? '#555555' // Background color for selected option
        : isFocused
        ? '#444444' // Background color for hovered option
        : '#2c2c2c',
      color: isSelected
        ? brandings.textColor
        : isFocused
        ? brandings.textColor
        : '#d0d0d0',
      '&:active': {
        backgroundColor: brandings.cardBgColor || '#666666',
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: '#aaaaaa', // Color for the placeholder text
    }),
    input: (base) => ({
      ...base,
      color: brandings.textColor, // Font color for input text
    }),
  }

  const CreateCategory = async (value, { values, setFieldValue }) => {
    const payload = {
      name: value,
      type: 'agency',
      company_id: company_id,
    }
    const { response } = await AppService.docs_category.createDocs({
      payload,
    })
    if (response) {
      categories.push({
        label: value,
        value: response.data?._id,
      })
      data.category?.push(response.data)
      setData({ ...data })
      setFieldValue('categoryId', response.data?._id)
      setCategories([...categories])
    }
  }

  const CreateSubCategory = async (value, { values, setFieldValue }) => {
    const payload = {
      name: value,
      category: values.categoryId,
      type: 'agency',
      company_id: company_id,
    }
    const { response } = await AppService.docs_sub_category.create({
      payload,
    })
    if (response) {
      subCategories.push({
        label: value,
        value: response.data?._id,
      })
      data.sub_category?.push(response.data)
      setData({ ...data })
      setFieldValue('subCategoryId', response.data?._id)
      setSubCategories([...subCategories])
    }
  }

  const handleSubmit = async (form, { setSubmitting }) => {
    setSubmitting(true)

    if (!form.title || !form.categoryId || !form.subCategoryId || !form.doc) {
      setSubmitting(false)
      return Swal.fire({
        title: 'Incomplete Form',
        text: 'Please fill out all required fields.',
        icon: 'warning',
        confirmButtonText: 'OK',
      })
    }

    const payload = {
      title: form.title,
      category: form.categoryId,
      sub_category: form.subCategoryId,
      type: 'agency',
      company_id: company_id,
      doc: form.doc,
    }

    if (isUpdate) payload._id = formState._id

    try {
      const { response } = await AppService.docs[
        isUpdate ? 'updateDocs' : 'createDocs'
      ]({
        payload,
      })

      if (response) {
        Swal.fire({
          title: isUpdate ? 'Updated Successfully!' : 'Saved Successfully!',
          text: `Your document has been ${isUpdate ? 'updated' : 'saved'}.`,
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setFormState(null) // Reset form state after successful save
      } else {
        throw new Error('Failed to save the document.')
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'There was an issue saving the document. Please try again.',
        icon: 'error',
        confirmButtonText: 'Retry',
      })
    } finally {
      setSubmitting(false)
    }
  }

  const onLoad = () => {
    return () => setFormState()
  }
  useEffect(onLoad, [])

  return (
    <Formik initialValues={{ ...initState }} onSubmit={handleSubmit}>
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <div className='px-4'>
            <div className='flex justify-between items-center'>
              <div
                style={{ color: brandings.textColor }}
                className='text-regular font-semibold uppercase'
              >
                {isUpdate ? 'Update ' : 'Create New '} Document
              </div>

              <div className='flex items-center'>
                <label className='flex items-center cursor-pointer mr-3'>
                  <span className='me-3 text-sm font-medium'>Draft</span>
                  <input
                    type='checkbox'
                    onChange={(e) =>
                      setFieldValue(
                        'status',
                        e.target.checked ? 'active' : 'inactive'
                      )
                    }
                    checked={values.status === 'active'}
                    className='sr-only peer'
                  />
                  <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  <span className='ms-3 text-sm font-medium'>Publish</span>
                </label>
                {/* <button
                  type='submit'
                  style={{ color: brandings.textColor }}
                  className=' bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Loading...' : 'Save'}
                </button> */}
                <Button
                  disabled={isSubmitting}
                  className='px-5 py-2.5 me-2 !mb-2'
                  type='submit'
                >
                  {isSubmitting ? 'Loading...' : 'Save'}
                </Button>
              </div>
            </div>
            <div className='my-3'>
              <div className='text-sm'>Categories</div>
              <CreatableSelect
                placeholder={'Select or type to create new category'}
                styles={customStyles}
                options={categories}
                value={categories.find(
                  (item) => item.value === values.categoryId
                )}
                onCreateOption={(v) =>
                  CreateCategory(v, { values, setFieldValue })
                }
                onChange={(e) => setFieldValue('categoryId', e.value)}
              />
            </div>
            {values.categoryId && (
              <div className='my-3'>
                <div className='text-sm'>Sub categories</div>
                <CreatableSelect
                  placeholder={'Select or type to create new sub-category'}
                  styles={customStyles}
                  options={subCategories.filter(
                    (subCat) => subCat.category === values.categoryId
                  )}
                  value={subCategories.find(
                    (item) => item.value === values.subCategoryId
                  )}
                  onCreateOption={(v) =>
                    CreateSubCategory(v, { values, setFieldValue })
                  }
                  onChange={(e) => setFieldValue('subCategoryId', e.value)}
                />
              </div>
            )}
            <div>
              <div className='text-sm'>Title</div>
              <Input
                placeholder='Document title'
                onChange={(e) => setFieldValue('title', e.target.value)}
                value={values.title}
              />
            </div>
            <div className='my-3'>
              <div className='font-bold'>Documents</div>
              <TextEditor
                handleChange={(e) => setFieldValue('doc', e)}
                data={values.doc}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Editor
