import React, { useEffect, useState } from 'react'
import { TABS, useDoc } from './doc.context'
import { useAppServices } from '../../hook/services'
import { useParams } from 'react-router-dom'
import Sidebar from './Sidebar'
import RelatedDoc from './RelatedDoc'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useTheme } from '../../context/theme.context'

export function PublishIcon({ size }: any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={size || '20px'}
      height={size || '20px'}
      viewBox='0 0 1920 1920'
    >
      <path
        d='M860.16 1373.227 490.773 1003.84 641.6 853.013l218.56 218.56 453.653-453.653 150.827 150.827-604.48 604.48ZM960 0C429.76 0 0 429.76 0 960s429.76 960 960 960c530.133 0 960-429.76 960-960S1490.133 0 960 0Z'
        fillRule='evenodd'
      />
    </svg>
  )
}

export function UnpublishIcon({ size }: any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={size || '20px'}
      height={size || '20px'}
      viewBox='0 0 1920 1920'
    >
      <path
        d='M213.333 960c0-167.36 56-321.707 149.44-446.4L1406.4 1557.227c-124.693 93.44-279.04 149.44-446.4 149.44-411.627 0-746.667-335.04-746.667-746.667m1493.334 0c0 167.36-56 321.707-149.44 446.4L513.6 362.773c124.693-93.44 279.04-149.44 446.4-149.44 411.627 0 746.667 335.04 746.667 746.667M960 0C429.76 0 0 429.76 0 960s429.76 960 960 960 960-429.76 960-960S1490.24 0 960 0'
        fillRule='evenodd'
      />
    </svg>
  )
}

export function DeleteIcon({ size }: any) {
  return (
    <svg
      fill='currentColor'
      width={size || '20px'}
      height={size || '20px'}
      viewBox='0 0 482.428 482.429'
    >
      <g>
        <g>
          <path d='M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098    c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117    h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828    C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879    C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096    c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266    c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979    V115.744z' />
          <path d='M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07    c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z' />
          <path d='M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07    c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z' />
          <path d='M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07    c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z' />
        </g>
      </g>
    </svg>
  )
}

export function EditIcon({ size }: any) {
  return (
    <svg
      fill='currentColor'
      width={size || '20px'}
      height={size || '20px'}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z'
      />
    </svg>
  )
}

export function Paint() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      width='1em'
      height='1em'
      fill='currentColor'
      className='tw-w-[14px] tw-h-[14px] tw-text-[#BEBEBE]'
    >
      <path d='M6.192 2.78c-.458-.677-.927-1.248-1.35-1.643a2.972 2.972 0 0 0-.71-.515c-.217-.104-.56-.205-.882-.02-.367.213-.427.63-.43.896-.003.304.064.664.173 1.044.196.687.556 1.528 1.035 2.402L.752 8.22c-.277.277-.269.656-.218.918.055.283.187.593.36.903.348.627.92 1.361 1.626 2.068.707.707 1.441 1.278 2.068 1.626.31.173.62.305.903.36.262.05.64.059.918-.218l5.615-5.615c.118.257.092.512.05.939-.03.292-.068.665-.073 1.176v.123h.003a1 1 0 0 0 1.993 0H14v-.057a1.01 1.01 0 0 0-.004-.117c-.055-1.25-.7-2.738-1.86-3.494a4.322 4.322 0 0 0-.211-.434c-.349-.626-.92-1.36-1.627-2.067-.707-.707-1.441-1.279-2.068-1.627-.31-.172-.62-.304-.903-.36-.262-.05-.64-.058-.918.219l-.217.216zM4.16 1.867c.381.356.844.922 1.311 1.632l-.704.705c-.382-.727-.66-1.402-.813-1.938a3.283 3.283 0 0 1-.131-.673c.091.061.204.15.337.274zm.394 3.965c.54.852 1.107 1.567 1.607 2.033a.5.5 0 1 0 .682-.732c-.453-.422-1.017-1.136-1.564-2.027l1.088-1.088c.054.12.115.243.183.365.349.627.92 1.361 1.627 2.068.706.707 1.44 1.278 2.068 1.626.122.068.244.13.365.183l-4.861 4.862a.571.571 0 0 1-.068-.01c-.137-.027-.342-.104-.608-.252-.524-.292-1.186-.8-1.846-1.46-.66-.66-1.168-1.32-1.46-1.846-.147-.265-.225-.47-.251-.607a.573.573 0 0 1-.01-.068l3.048-3.047zm2.87-1.935a2.44 2.44 0 0 1-.241-.561c.135.033.324.11.562.241.524.292 1.186.8 1.846 1.46.45.45.83.901 1.118 1.31a3.497 3.497 0 0 0-1.066.091 11.27 11.27 0 0 1-.76-.694c-.66-.66-1.167-1.322-1.458-1.847z'></path>
    </svg>
  )
}

export function Spinner() {
  return (
    <div role='status'>
      <svg
        aria-hidden='true'
        className='w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600'
        viewBox='0 0 100 101'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
          fill='currentColor'
        />
        <path
          d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
          fill='currentFill'
        />
      </svg>
      <span className='sr-only'>Loading...</span>
    </div>
  )
}

function DocView() {
  const {
    activeDoc,
    activeCat,
    setRelatedDocs,
    getLanguage,
    setActiveTab,
    setFormState, // Access setFormState from context
    onLoad,
  } = useDoc()

  const [doc, setDoc] = useState({})
  const [loader, setLoader] = useState(true)
  const AppService = useAppServices()
  const { company_id } = useParams()
  const [duplicating, setDuplicating] = useState(false)
  const [processingStatus, setProcessingStatus] = useState(false)
  const { brandings } = useTheme()

  const getDocsData = async () => {
    setLoader(true)
    const { response } = await AppService.docs.getdetails({
      query: `company_id=${company_id}&_id=${activeDoc._id}`,
    })

    // Handling Agency managed document
    let existingDoc = response.data?.interactedDocuments
    if (existingDoc) {
      existingDoc = {
        ...existingDoc.docId,
        snapshot: existingDoc,
      }
    }
    if (response) {
      const filter_doc =
        existingDoc ||
        response.data?.docs?.filter((doc) => doc._id == activeDoc._id)[0]
      const relatedDocs = response.data?.docs?.filter(
        (item) =>
          item.sub_category == filter_doc.sub_category &&
          item._id != filter_doc._id
      )
      setRelatedDocs(relatedDocs)
      setDoc(filter_doc)
      setLoader(false)
    } else {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (activeDoc) getDocsData()
  }, [activeDoc?._id])

  const handleEditDoc = () => {
    setFormState({
      categoryId: activeCat._id,
      subCategoryId: activeDoc.sub_category,
      title: activeDoc.title,
      doc: activeDoc.doc,
      status: activeDoc.status,
      _id: activeDoc._id,
    })
    setActiveTab(TABS.EDITOR)
  }

  const handleCopy = async () => {
    setDuplicating(true)
    const payload = {
      type: 'clone',
      company_id: company_id,
      sub_category: activeDoc.sub_category,
      category: activeCat._id,
      _id: activeDoc._id,
    }
    console.log(payload, 'payload')
    const { response } = await AppService.docs.take_snapshot({
      payload,
    })
    if (response) {
      Swal.fire('Document successfully duplicated.')
      onLoad()
    }
    setDuplicating(false)
  }

  const handleStatus = async (status) => {
    setProcessingStatus(true)
    const payload = {
      status,
      docId: doc._id,
      snapshotId: doc.snapshot?._id,
    }
    const { response } = await AppService.docs.handle_status({
      payload,
    })
    setProcessingStatus(false)
    if (response) {
      Swal.fire('Document status updated successfully.')
      onLoad()
    }
  }

  const handleDeleteDoc = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this document!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    })
    if (isConfirmed) {
      const { response } = await AppService.docs.delete({
        query: `_id=${activeDoc._id}`,
      })
      if (response) {
        Swal.fire('Document deleted successfully.')
        onLoad()
      }
    }
  }

  const isActive = () => {
    if (doc.type == 'agency' && doc.status === 'active') return true
    if (
      doc.type == 'super-admin' &&
      !doc?.snapshot?.status &&
      doc.status == 'active'
    )
      return true
    if (doc.snapshot?.status == 'active') return true
    return false
  }

  return (
    <main className='flex-1 p-6 h-[calc(100vh-390px)] min-h-96 overflow-auto scrollbar-hidden'>
      {loader ? (
        <div role='status'>Loading ...</div>
      ) : (
        <>
          <div className='flex items-end gap-4 justify-between mb-6'>
            {activeCat.name && (
              <h1
                style={{
                  color: brandings.textColor,
                }}
                className='text-xs text-white font-bold m-0'
              >
                {getLanguage(activeCat, 'name')}
              </h1>
            )}
            <div className='flex justify-end gap-4 items-center'>
              {doc.type !== 'super-admin' && (
                <>
                  <div
                    onClick={handleEditDoc}
                    className='flex items-center cursor-pointer gap-2 text-xs text-blue-600 hover:underline'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-4 h-4'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M16.862 4.487l1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10'
                      />
                    </svg>
                    Edit this page
                  </div>
                  <div
                    data-tooltip-id='delete'
                    data-tooltip-content='Delete document'
                    className='cursor-pointer'
                    onClick={handleDeleteDoc}
                  >
                    <DeleteIcon />
                    <Tooltip id='delete' />
                  </div>
                </>
              )}
              <div
                data-tooltip-id='copy'
                data-tooltip-content='Duplicate document'
                onClick={handleCopy}
              >
                {duplicating ? (
                  <Spinner />
                ) : (
                  <svg
                    viewBox='0 0 24 24'
                    fill='none'
                    width={20}
                    height={20}
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    ></g>
                    <g id='SVGRepo_iconCarrier'>
                      <g id='style=linear'>
                        <g id='copy'>
                          <path
                            id='rec'
                            d='M17.8333 18C20.1345 18 22 16.0972 22 13.75V5.25C22 2.90279 20.1345 1 17.8333 1H11.1667C8.86548 1 7 2.90279 7 5.25'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          ></path>{' '}
                          <path
                            id='rec_2'
                            d='M2 10.25C2 7.90279 3.86548 6 6.16667 6H12.8333C15.1345 6 17 7.90279 17 10.25V18.75C17 21.0972 15.1345 23 12.8333 23H6.16667C3.86548 23 2 21.0972 2 18.75V10.25Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                          ></path>{' '}
                        </g>{' '}
                      </g>{' '}
                    </g>
                  </svg>
                )}
              </div>
              <Tooltip id='copy' />
              <div className='flex items-center cursor-pointer'>
                {processingStatus ? (
                  <Spinner />
                ) : isActive() ? (
                  <div
                    onClick={() => handleStatus('inactive')}
                    className='cursor-pointer'
                    data-tooltip-id='unpublish'
                    data-tooltip-content='Draft this document'
                  >
                    <PublishIcon />
                    <Tooltip id='unpublish' />
                  </div>
                ) : (
                  <div
                    onClick={() => handleStatus('active')}
                    className='cursor-pointer'
                    data-tooltip-id='publish'
                    data-tooltip-content='Publish this document'
                  >
                    <UnpublishIcon />
                    <Tooltip id='publish' />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <h1
              style={{
                color: brandings.textColor,
              }}
              className='text-3xl text-white font-bold'
            >
              {getLanguage(doc, 'title')}
            </h1>
          </div>

          <div
            style={{
              color: brandings.textColor,
            }}
            className='text-white'
            dangerouslySetInnerHTML={{
              __html: getLanguage(doc, 'doc'),
            }}
          ></div>
        </>
      )}
    </main>
  )
}

function Doc() {
  const { relatedDocs } = useDoc()
  return (
    <div className='flex'>
      <Sidebar />
      <DocView />
      {relatedDocs?.length ? <RelatedDoc /> : ''}
    </div>
  )
}

export default Doc
