import React from 'react'
import { useParams } from 'react-router-dom'
import { TABS, useDoc } from './doc.context'
import { useTheme } from '../../context/theme.context'
import Button from './comp/Button'
import Swal from 'sweetalert2'
import { useAgency } from '../../context/agency.context'

function TabItem({ active = false, label = '', onClick }: any) {
  const { brandings, getHoverProps } = useTheme()

  return (
    <li onClick={onClick} className='me-2 '>
      <div
        style={{
          color: active ? brandings.textColor : brandings.textSecondaryColor,
        }}
        {...getHoverProps({ active })}
        className={`inline-block cursor-pointer mx-2 px-1.5 pb-0.5 border-b-2 rounded-t-lg ${
          active
            ? 'border-white active'
            : 'border-transparent hover:border-gray-300'
        }`}
      >
        {label}
      </div>
    </li>
  )
}

function Tabs() {
  const { agency } = useAgency()
  const { brandings } = useTheme()
  const { activeTab, setActiveTab } = useDoc()
  const { company_id, app_id } = useParams()

  const handleTab = (tab) => {
    setActiveTab(tab)
  }

  // const copyCustomMenuLink = () => {
  //   const text = `${agency.domain || window.location.origin}/docs`

  //   navigator.clipboard
  //     .writeText(text)
  //     .then(() => {
  //       Swal.fire('Link copied to clipboard')
  //     })
  //     .catch((err) => {
  //       console.error('Failed to copy: ', err)
  //     })
  // }

  const copyCustomMenuLink = () => {
    if (!agency?.domain) {
      Swal.fire('Please configure your domain in settings')
    }
    const text = `https://${agency?.domain}/${app_id}/${company_id}/documents`

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    // Append the textarea to the body
    document.body.appendChild(textarea)

    // Select the text inside the textarea
    textarea.select()

    // Execute the copy command
    document.execCommand('copy')
    Swal.fire('copied to clipboard')
    // Remove the textarea from the DOM
    document.body.removeChild(textarea)
  }

  return (
    <div className='p-4'>
      <div
        style={{ color: brandings.textSecondaryColor }}
        className='text-sm font-medium text-center border-b border-gray-700'
      >
        <div className='flex items-center justify-between'>
          <ul className='flex flex-wrap -mb-px'>
            <TabItem
              label='Documents'
              onClick={() => handleTab(TABS.DOC)}
              active={activeTab === TABS.DOC}
            />
            <TabItem
              label='FAQs'
              onClick={() => handleTab(TABS.FAQ)}
              active={activeTab === TABS.FAQ}
            />
            <TabItem
              label='Editor'
              onClick={() => handleTab(TABS.EDITOR)}
              active={activeTab === TABS.EDITOR}
            />
            <TabItem
              label='Settings'
              onClick={() => handleTab(TABS.SETTING)}
              active={activeTab === TABS.SETTING}
            />
          </ul>
          <div>
            <Button onClick={copyCustomMenuLink}>Copy Custom Menu Link</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabs
