import React, { useEffect, useState } from 'react'
import { useAppServices } from '../../hook/services'
import { TABS, useDoc } from './doc.context'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import { useTheme } from '../../context/theme.context'
import Button from './comp/Button'
import { DeleteIcon, PublishIcon, Spinner, UnpublishIcon } from './Doc'
import { useRef } from 'react'

const getStatus = (doc) => {
  if (doc.snapshot && doc.snapshot.status == 'active') return 'inactive'
  if (doc.snapshot && doc.snapshot.status == 'inactive') return 'active'
  if (!doc.snapshot && doc.status == 'active') return 'inactive'
  if (!doc.snapshot && doc.status == 'inactive') return 'active'
}

const getSubCatStatus = (doc) => {
  if (doc.snapshot && doc.snapshot.status == 'active') return 'inactive'
  if (doc.snapshot && doc.snapshot.status == 'inactive') return 'active'
  if (!doc.snapshot && doc.status == 'active') return 'inactive'
  if (!doc.snapshot && (doc.status == 'draft' || doc.status == 'inactive'))
    return 'active'
}

export const isDisabled = (doc) => {
  if (!doc.snapshot && doc.status == 'inactive') return true
  if (doc.snapshot && doc.snapshot.status == 'inactive') return true
  return false
}

const isDisabledSubCat = (doc) => {
  if (!doc.snapshot && doc.status == 'inactive') return true
  if (doc.snapshot && doc.snapshot.status == 'inactive') return true
  return false
}

function DotMenu() {
  return (
    <svg
      fill='currentColor'
      height='12px'
      width='12px'
      version='1.1'
      viewBox='0 0 32.055 32.055'
      className='rotate-90'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <g>
          <path d='M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967 C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967 s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967 c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z'></path>{' '}
        </g>
      </g>
    </svg>
  )
}

function ArrowIcon({
  isExpanded,
  size = 12,
}: {
  isExpanded: boolean,
  size?: number,
}) {
  return (
    <svg
      viewBox='0 0 1024 1024'
      width={size}
      height={size}
      className={`transition-transform duration-150 ${
        isExpanded ? 'rotate-[270deg]' : 'rotate-180'
      }`}
      fill='currentColor'
    >
      <path
        d='M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z'
        fill='currentColor'
      ></path>
    </svg>
  )
}

function DropdoownItem({ name, icon, onClick }: any) {
  const { brandings } = useTheme()

  return (
    <div
      style={{
        color: brandings.textColor,
        backgroundColor: brandings.cardBgColor,
      }}
      className={`px-4 py-2 text-sm hover:bg-gray-800 flex items-center gap-3 cursor-pointer`}
      onClick={onClick}
    >
      {icon}
      {name}
    </div>
  )
}

function GroupTitle({
  title,
  disabled,
  category,
}: {
  title: string,
  disabled?: boolean,
  category: object,
}) {
  const { brandings } = useTheme()
  const { onLoad } = useDoc()
  const { company_id } = useParams()
  const AppService = useAppServices()
  const [showDropdown, setShowDropdown] = useState()

  const handleStatus = async () => {
    const payload = {
      categoryId: category._id,
      status: getStatus(category),
      snapshotId: category.snapshot?._id,
      companyId: company_id,
    }

    const { response } = await AppService.docs_category.updateStatus({
      payload,
    })
    if (response) {
      Swal.fire('Category Status Changed, Successfully')
      onLoad()
    }
  }

  const deleteCategory = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this category!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    })
    if (isConfirmed) {
      const { response } = await AppService.docs_category.delete({
        query: `_id=${category._id}`,
      })
      if (response) {
        Swal.fire('Category Deleted, Successfully')
        onLoad()
      }
    }
  }

  return (
    <div className='flex justify-between items-center mb-2.5'>
      <h2
        style={{ color: brandings.textColor }}
        className={`text-xs font-semibold m-0 uppercase ${
          disabled ? 'opacity-50 hover:text-gray-300 cursor-default' : ''
        }`}
      >
        {title}
      </h2>
      {/* <label className='cursor-pointer m-0 relative'>
        <input
          type='checkbox'
          onChange={handleStatus}
          checked={!disabled}
          className='sr-only peer'
        />
        <div className="relative w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 dark:peer-checked:bg-blue-600"></div>
      </label> */}
      <div className='relative'>
        <div
          onClick={() => setShowDropdown(!showDropdown)}
          className='cursor-pointer'
        >
          <DotMenu />
        </div>
        {showDropdown ? (
          <div className='absolute transition-all duration-150 right-0 z-50 mt-2 w-44 origin-top-right rounded-md bg-gray-700 shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <div className='py-1'>
              <DropdoownItem
                onClick={handleStatus}
                name={disabled ? 'Publish' : 'Draft'}
                icon={
                  disabled ? (
                    <PublishIcon size={14} />
                  ) : (
                    <UnpublishIcon size={14} />
                  )
                }
              />
              {category?.type == 'agency' ? (
                <DropdoownItem
                  onClick={deleteCategory}
                  name='Delete'
                  icon={<DeleteIcon size={14} />}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

function NavItem({
  title,
  children,
  active,
  disabled,
  expanded = false,
  doc,
  cat,
  sub_category,
}: {
  title: string,
  active?: boolean,
  disabled?: boolean,
  expanded?: boolean,
  doc?: object,
  cat?: object,
  sub_category?: object,
  children?: React.ReactNode,
}) {
  const { brandings, getHoverProps } = useTheme()
  const { setActiveDoc, setActiveCat, onLoad } = useDoc()
  const [isExpanded, setIsExpanded] = useState(false)
  const [showDropdown, setShowDropdown] = useState()
  const AppService = useAppServices()
  const { company_id } = useParams()
  const ListRef = useRef()

  const handleSelectedDoc = () => {
    if (disabled) return
    if (children) return setIsExpanded(!isExpanded)

    setActiveDoc(doc)
    setActiveCat(cat)
  }

  useEffect(() => {
    if (expanded) setIsExpanded(expanded)
  }, [expanded])

  const handleStatus = async () => {
    const payload = {
      subCategoryId: sub_category._id,
      status: getSubCatStatus(sub_category),
      snapshotId: sub_category.snapshot?._id,
      companyId: company_id,
    }

    const { response } = await AppService.docs_sub_category.updateStatus({
      payload,
    })
    if (response) {
      Swal.fire('Category Status Changed, Successfully')
      onLoad()
    }
  }

  const deleteSubCategory = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this category!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    })
    if (isConfirmed) {
      const { response } = await AppService.docs_sub_category.delete({
        query: `_id=${sub_category._id}`,
      })
      if (response) {
        Swal.fire('Category Deleted, Successfully')
        onLoad()
      }
    }
  }

  const isLastElement =
    ListRef.current?.parentElement?.parentElement?.nextElementSibling?.classList?.contains(
      'powered-by'
    )

  return (
    <li ref={ListRef} className='text-sm'>
      <div
        style={{
          color: active ? brandings.textColor : brandings.textSecondaryColor,
          backgroundColor: active
            ? brandings.cardBgColor
            : brandings.pageBackground,
        }}
        {...getHoverProps({ active })}
        className={`flex items-center gap-4 group/dropdown p-2 ${
          showDropdown && isLastElement ? 'mb-56' : ''
        } ${active ? brandings.cardBgColor : ''} ${
          disabled
            ? 'opacity-50 cursor-default'
            : `hover:bg-${brandings.buttonHoverColor} hover:rounded-md cursor-pointer`
        }`}
        onClick={handleSelectedDoc}
      >
        {children && (
          <div className=''>
            <ArrowIcon isExpanded={isExpanded} />
          </div>
        )}
        <span>{title}</span>
        {sub_category ? (
          <div className='relative ml-auto invisible group-hover/dropdown:visible'>
            <div
              onClick={(e) => {
                e.stopPropagation()
                setShowDropdown(!showDropdown)
              }}
              className='cursor-pointer'
            >
              <DotMenu />
            </div>
            {showDropdown ? (
              <div className='absolute transition-all duration-150 right-0 z-50 mt-2 w-44 max-h-56 overflow-auto origin-top-right rounded-md bg-gray-700 shadow-lg ring-1 ring-black/5 focus:outline-none'>
                <div className='py-1'>
                  <DropdoownItem
                    onClick={handleStatus}
                    name={disabled ? 'Publish' : 'Draft'}
                    icon={
                      disabled ? (
                        <PublishIcon size={14} />
                      ) : (
                        <UnpublishIcon size={14} />
                      )
                    }
                  />
                  {sub_category?.type == 'agency' ? (
                    <DropdoownItem
                      onClick={deleteSubCategory}
                      name='Delete'
                      icon={<DeleteIcon size={14} />}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      {isExpanded && children && (
        <ul className='pl-2 border-l border-gray-600'>{children}</ul>
      )}
    </li>
  )
}

function DocList({ sub_category, category }: any) {
  const AppService = useAppServices()
  const [docs, setDocs] = useState([])
  const [docsloader, setdocsloader] = useState(true)
  const { activeDoc, getLanguage } = useDoc()

  const getDocsData = async () => {
    setdocsloader(true)
    const { response } = await AppService.docs.get({
      query: `category=${sub_category.category}`,
    })
    if (response) {
      setDocs(response.data)
      setdocsloader(false)
    } else {
      setdocsloader(false)
    }
  }

  const onLoad = () => {
    getDocsData()
  }
  useEffect(() => {
    onLoad()
  }, [])

  return docsloader ? (
    <Spinner />
  ) : (
    docs
      ?.filter((doc) => doc.sub_category == sub_category._id)
      ?.map((doc) => (
        <NavItem
          key={doc._id}
          title={getLanguage(doc, 'title')}
          doc={doc}
          cat={category}
          active={activeDoc._id == doc._id}
        />
      ))
  )
}

const PoweredBy = () => {
  const { brandings, generalInfos } = useTheme()
  return (
    <div
      style={{
        color: brandings.textColor,
        backgroundColor: brandings.pageBackground,
      }}
      className='flex powered-by sticky -mt-8 pt-8 bottom-0 z-10 pb-6  items-center justify-start text-xs font-semibold uppercase pr-6'
    >
      <svg
        fill='currentColor'
        height='30px'
        width='30px'
        version='1.1'
        viewBox='0 0 487.5 487.5'
      >
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g
          id='SVGRepo_tracerCarrier'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></g>
        <g id='SVGRepo_iconCarrier'>
          <g>
            <g>
              <path d='M437,12.3C437,5.5,431.5,0,424.7,0H126.3C84.4,0,50.4,34.1,50.4,75.9v335.7c0,41.9,34.1,75.9,75.9,75.9h298.5 c6.8,0,12.3-5.5,12.3-12.3V139.6c0-6.8-5.5-12.3-12.3-12.3H126.3c-28.3,0-51.4-23.1-51.4-51.4S98,24.5,126.3,24.5h298.5 C431.5,24.5,437,19,437,12.3z M126.3,151.8h286.2V463H126.3c-28.3,0-51.4-23.1-51.4-51.4V131.7 C88.4,144.2,106.5,151.8,126.3,151.8z'></path>{' '}
              <path d='M130.5,64.8c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.3,12.3,12.3h280.1c6.8,0,12.3-5.5,12.3-12.3s-5.5-12.3-12.3-12.3H130.5z'></path>
              <path d='M178,397.7c6.3,2.4,13.4-0.7,15.8-7.1l17.9-46.8h62.7c0.5,0,0.9-0.1,1.3-0.1l17.9,46.9c1.9,4.9,6.5,7.9,11.4,7.9 c1.5,0,2.9-0.3,4.4-0.8c6.3-2.4,9.5-9.5,7.1-15.8l-54-141.2c-3-7.9-10.4-13-18.8-13c-8.4,0-15.8,5.1-18.8,13l-54,141.2 C168.5,388.2,171.7,395.2,178,397.7z M243.7,260l22.7,59.3h-45.3L243.7,260z'></path>{' '}
            </g>
          </g>
        </g>
      </svg>
      <span className='ml-2'>Powered By {generalInfos.poweredBy}</span>
    </div>
  )
}

function Sidebar() {
  const { data, activeDoc, getLanguage, setActiveTab } = useDoc()
  const { brandings } = useTheme()

  // const snapshots = data?.snapshot_data || []

  return (
    <aside className='w-64 h-[calc(100vh-390px)] min-h-96 flex flex-col relative'>
      {/* Scrollable Content */}
      <div className='flex-1 overflow-auto p-6 pb-0 custom-scrollbar'>
        <Button type='submit' onClick={() => setActiveTab(TABS.EDITOR)}>
          {'Add New Document'}
        </Button>
        {data?.category?.map((category) => {
          return (
            <div key={category._id}>
              <GroupTitle
                title={getLanguage(category, 'name')}
                category={category}
                disabled={isDisabled(category)}
              />
              <ul className='space-y-2 mb-8'>
                {data?.sub_category?.map(
                  (sub_cat) =>
                    category._id == sub_cat.category && (
                      <NavItem
                        key={sub_cat._id}
                        title={getLanguage(sub_cat, 'name')}
                        active={sub_cat._id === activeDoc.sub_category}
                        expanded={sub_cat._id === activeDoc.sub_category}
                        disabled={isDisabledSubCat(sub_cat)}
                        sub_category={sub_cat}
                      >
                        <DocList sub_category={sub_cat} category={category} />
                      </NavItem>
                    )
                )}
              </ul>
            </div>
          )
        })}
        <PoweredBy />
      </div>
    </aside>
  )
}

export default Sidebar
